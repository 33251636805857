import AuthService from '@apiService/AuthService';
import Spinner from '@components/partials/Spinner';
import { currencyFormatter } from '@helper/currencyFormatter';
import { useEffect, useState } from 'react';
import { withNamespaces } from 'react-i18next';
import { useSelector } from 'react-redux';
import moment from 'moment';

const CustomerLoyalty = () => {
  const loginDetails = useSelector((state) => state.auth);
  console.log("LOGIN", { loginDetails });

  const { loggedInUser, guestUser } = loginDetails;
  const [userBookings, setUserBookings] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [bookingFilters] = useState([
    { type: 2, name: 'Ticket', isSelected: true },
    { type: 3, name: 'F&B', isSelected: false },
  ]);
  const [selectedBookingFilter, setSelectedBookingFilter] = useState(2);

  const handleFilterClick = async (type) => {
    setSelectedBookingFilter(type);
    await getCustomerLoyaltyData(type);
  };

  const getCustomerLoyaltyData = async (type) => {
    try {
      setIsLoading(true);
      const response = await AuthService.GetCustomerLoyaltyPoints({
        isCustomer: loggedInUser?.hasOwnProperty('cust_id'),
        customer_id: loggedInUser?.cust_id || guestUser?.guest_id,
        type,
      });

      console.log("Response Data", response);

      if (response.data.status) {
        let bookings =
          type == 2
            ? response.data.TicketRecords
            : response.data.FnbRecords;
        setUserBookings(bookings);
      } else {
        setUserBookings([]);
      }
    } catch (error) {
      console.error('Error fetching customer loyalty data', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getCustomerLoyaltyData(selectedBookingFilter);
  }, []);

  const totalEarnPoints = userBookings.reduce(
    (acc, obj) => acc + (obj.earnings_points || 0),
    0
  );
  const totalBurnPoints = userBookings.reduce(
    (acc, obj) => acc + (obj.burnings_points || 0),
    0
  );

  return (
    <>
      <div className="d-flex">
        {bookingFilters.map((filter) => (
          <div
            key={filter.type}
            className={`user-history-filters ${
              selectedBookingFilter === filter.type
                ? 'user-history-filters-selected'
                : ''
            }`}
            onClick={() => handleFilterClick(filter.type)}
          >
            {filter.name}
          </div>
        ))}
      </div>

      <div className="user-history-table-container">
        <table className="user-history-table">
          {selectedBookingFilter == 2 ? (
            <>
              {/* Ticket Header */}
              <thead className="user-history-table-heading">
                  <th className="text-left">Booking Code</th>
                  <th className="text-left">Show DateTime</th>
                  <th className="text-left">Trans. DateTime</th>
                  <th className="text-right">Amount</th>
                  <th className="text-right">Earned</th>
                  <th className="text-right">Burned</th>
              </thead>
              <tbody>
                {isLoading ? (
                  <tr>
                    <td colSpan={6} className="text-center">
                      <Spinner />
                    </td>
                  </tr>
                ) : !userBookings.length ? (
                  <tr>
                    <td colSpan={6} className="text-center">
                      No Records
                    </td>
                  </tr>
                ) : (
                  userBookings.map((booking) => (
                    <tr class="user-history-table-row" key={booking.sb_booking_code}>
                      <td className="text-left">
                        {booking.sb_booking_code}
                      </td>
                      <td className="text-left">
                        {moment(booking.t_show_date).format('DD-MMM-YYYY') +
                          ' - ' +
                          moment(booking.t_show_time, 'HH:mm:ss').format(
                            'hh:mm A'
                          )}
                      </td>
                      <td className="text-left">
                        {moment(booking.t_booking_date_time).format(
                          'DD-MMM-YYYY hh:mm A'
                        )}
                      </td>
                      <td className="text-right">
                        {currencyFormatter(booking.sb_total_amount)}
                      </td>
                      <td className="text-right">
                        {booking.earnings_points || 0}
                      </td>
                      <td className="text-right">
                        {booking.burnings_points || 0}
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </>
          ) : (
            <>
              {/* F&B Header */}
              <thead className="user-history-table-heading">   
                  <th className="text-left">Order Code</th>
                  <th className="text-left">Trans. DateTime</th>
                  <th className="text-right">Amount</th>
                  <th className="text-right">Earned</th>
                  <th className="text-right">Burned</th>
              </thead>
              <tbody>
                {isLoading ? (
                  <tr>
                    <td colSpan={5} className="text-center">
                      <Spinner />
                    </td>
                  </tr>
                ) : !userBookings.length ? (
                  <tr>
                    <td colSpan={5} className="text-center">
                      No Records
                    </td>
                  </tr>
                ) : (
                  userBookings.map((booking) => (
                    <tr class="user-history-table-row" key={booking.order_code}>
                      <td className="text-left">{booking.order_code}</td>
                      <td className="text-left">
                        {moment(booking.ft_date_time).format(
                          'DD-MMM-YYYY hh:mm A'
                        )}
                      </td>
                      <td className="text-right">
                        {currencyFormatter(
                          booking.ft_gross_amount_before_offer
                        )}
                      </td>
                      <td className="text-right">
                        {booking.earnings_points || 0}
                      </td>
                      <td className="text-right">
                        {booking.burnings_points || 0}
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </>
          )}

          {/* Total Row */}
          <tfoot>
            <tr className="user-history-table-row total-row">
              <td className="text-left">
                <strong>Total</strong>
              </td>
              <td colSpan={selectedBookingFilter === 2 ? 3 : 2}></td>
              <td className="text-right">
                <strong>{totalEarnPoints}</strong>
              </td>
              <td className="text-right">
                <strong>{totalBurnPoints}</strong>
              </td>
            </tr>
            <tr className="user-history-table-row total-row">
              <td className="text-left">
                
              </td>
              <td colSpan={selectedBookingFilter === 2 ? 3 : 2}></td>
              <td colSpan={2} className="text-right">
                <strong>
                  Total Points: {totalEarnPoints - totalBurnPoints}
                </strong>
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    </>
  );
};

export default withNamespaces()(CustomerLoyalty);
